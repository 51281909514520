import { createContext, PropsWithChildren, useContext, useState, JSX } from 'react';
import { ApiGetPackagesResponse, ApiListOptions } from '@ibe/api';
import { PackageParams } from '@ibe/components';

export type SearchProperties = {
  params?: Partial<PackageParams>;
  listOptions?: ApiListOptions;
  response?: ApiGetPackagesResponse | null;
};

export type SearchPropertiesContextType = {
  searchProperties: SearchProperties | undefined;
  setSearchProperties: (searchProperties: SearchProperties) => void;
};

export const SearchPropertiesContext = createContext<SearchPropertiesContextType>({
  searchProperties: undefined,
  setSearchProperties: () => null
});

export const SearchPropertiesProvider = ({ children }: PropsWithChildren<{}>): JSX.Element => {
  const [searchProperties, setSearchProperties] = useState<SearchProperties | undefined>(undefined);

  return (
    <SearchPropertiesContext.Provider value={{ searchProperties, setSearchProperties }}>
      {children}
    </SearchPropertiesContext.Provider>
  );
};

export const useSearchProperties = (): SearchPropertiesContextType => {
  return useContext<SearchPropertiesContextType>(SearchPropertiesContext);
};
